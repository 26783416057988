import React from "react";

import StudentListItem from "./student-list-item";

export default function CollectionList({
  students,
  projectsOrStudents,
  backgroundColor,
}) {
  //grab width using util

  // setStudentList(students);

  let projectList = students.map((student) => {
    return (
      <StudentListItem
        key={student.slug}
        student={student}
        projectsOrStudents={projectsOrStudents}
      />
    );
  });

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="collectionListContainer"
    >
      <ul className="collectionList">{projectList}</ul>
    </div>
  );
}
