import React from "react";
import { Link } from "gatsby";
import Line1 from "../assets/lines/line1.svg";
import Line2 from "../assets/lines/line2.svg";
import Line3 from "../assets/lines/line3.svg";
import Line4 from "../assets/lines/line4.svg";
import Line5 from "../assets/lines/line5.svg";
import Line6 from "../assets/lines/line6.svg";
import Line7 from "../assets/lines/line7.svg";
import Line8 from "../assets/lines/line8.svg";
import Line9 from "../assets/lines/line9.svg";
import Line10 from "../assets/lines/line10.svg";
import Line11 from "../assets/lines/line11.svg";
import Line12 from "../assets/lines/line12.svg";
import Line13 from "../assets/lines/line13.svg";
import Line14 from "../assets/lines/line14.svg";
import Line15 from "../assets/lines/line8.svg";

const StudentListItem = ({ student, projectsOrStudents }) => {
  let allLines = [
    Line1,
    Line2,
    Line3,
    Line4,
    Line5,
    Line6,
    Line7,
    Line8,
    Line9,
    Line10,
    Line11,
    Line12,
    Line13,
    Line14,
    Line15,
  ];

  let lineToShow;

  {
    student.collection
      ? (lineToShow = allLines[student.collection[0].lineNumber])
      : (lineToShow = allLines[0]);
  }
  return (
    <React.Fragment key={student.slug}>
      <Link to={`/students/${student.slug}`}>
        <li className="studentListItem">
          <span className="studentListText left">
            {projectsOrStudents === "both"
              ? student.projectName
              : student.studentName}
          </span>
          <div className="line">
            <img src={lineToShow} />
          </div>
          {projectsOrStudents === "both" && (
            <span className="studentListText right">{student.studentName}</span>
          )}
        </li>
      </Link>
    </React.Fragment>
  );
};
export default StudentListItem;
