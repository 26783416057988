import React, { useState } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import CollectionList from "../components/CollectionList";
import CollectionNav from "../components/CollectionNav";

const Collection = ({ data }) => {
  let collectionPage = data.contentfulCollection;
  let allCollections = data.allContentfulCollection.nodes;

  const [clicked, setClicked] = useState(false);

  function onClick() {
    setClicked(!clicked);
  }

  return (
    <Layout>
      <Seo title={`${collectionPage.title} • KSA Foundation 2021`} />
      <div className="collectionBody">
        <div className="collectionBodyContainer">
          <div className="collectionHeroText">
            <h1 className="collectionTitle">{collectionPage.title}</h1>
          </div>
          <div className={"collectionHeroWrapper "} onClick={() => onClick()}>
            {collectionPage.heroImage && (
              <GatsbyImage
                loading="eager"
                objectFit="cover"
                placeholder="blurred"
                className="collectionHero"
                image={getImage(collectionPage.heroImage)}
                alt={
                  collectionPage.heroImage
                    ? collectionPage.heroImage.title
                    : `Kingston Foundation 2021 collection`
                }
              />
            )}
            <div className="collectionHeroOverlay ">
              {collectionPage.portraitImage && (
                <GatsbyImage
                  placeholder="blurred"
                  loading="eager"
                  objectFit="cover"
                  className={
                    "collectionHeroOverlayInner " +
                    (clicked ? "collectionHeroWrapperClicked" : "")
                  }
                  image={getImage(collectionPage.portraitImage)}
                  alt={
                    collectionPage.portraitImage.title
                      ? collectionPage.portraitImage.title
                      : `Kingston Foundation 2021 collection`
                  }
                />
              )}
            </div>
          </div>
          <div className="collectionPageLower">
            <CollectionList
              students={collectionPage.student}
              projectsOrStudents="both"
              backgroundColor="#fefdff"
            />
          </div>

          <CollectionNav
            collectionPage={collectionPage}
            allCollection={allCollections}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Collection;

export const pageQuery = graphql`
  query ($collection: String) {
    allContentfulCollection {
      nodes {
        id
        title
        heroImage {
          gatsbyImageData
          title
        }
        portraitImage {
          gatsbyImageData
          title
        }
        student {
          collection {
            lineNumber
          }
          studentName
          slug
          projectName
          heroImage {
            gatsbyImageData
            id
            title
          }
        }
      }
    }
    contentfulCollection(title: { in: [$collection] }) {
      id
      title
      heroImage {
        gatsbyImageData
        title
      }
      portraitImage {
        gatsbyImageData
        title
      }
      student {
        collection {
          lineNumber
        }
        studentName
        slug
        projectName
        heroImage {
          gatsbyImageData
          id
          title
        }
      }
    }
  }
`;
