import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

// Utilities
import kebabCase from "lodash/kebabCase";

export default function CollectionNav({ collectionPage, allCollection }) {
  const [nextCollection, setNextCollection] = useState(0);

  const [prevCollection, setPrevCollection] = useState(0);

  useEffect(() => {
    runNextCollection();
  }, []);

  console.log(allCollection);

  function runNextCollection() {
    //find what number the current student is in the index
    let currentCollectionIndex = allCollection.findIndex(function (collection) {
      if (collectionPage.title === collection.title) return true;
      else {
        return false;
      }
    });

    //Now, if we have successfully found the student (-1 is returned if we fail)
    if (currentCollectionIndex >= 0) {
      //if the index is at the end of the collection, then return 0 to loop back to start
      if (currentCollectionIndex >= allCollection.length - 1) {
        setNextCollection(allCollection[0]);
        setPrevCollection(allCollection[currentCollectionIndex - 1]);
      } else if (currentCollectionIndex === 0) {
        setNextCollection(allCollection[currentCollectionIndex + 1]);
        setPrevCollection(allCollection[allCollection.length - 1]);
      } else {
        //set nextStudent to the next student
        setNextCollection(allCollection[currentCollectionIndex + 1]);
        setPrevCollection(allCollection[currentCollectionIndex - 1]);
      }
    }
  }
  return (
    <div className="collectionNav">
      <Link
        className="nextCollection"
        to={`/exhibition/${kebabCase(nextCollection.title)}/`}
      >
        {nextCollection.title}
        <svg
          width="17"
          height="13"
          viewBox="0 0 17 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10.3333 1L16 6.8M16 6.8L10.3333 12.6M16 6.8L-2.53526e-07 6.8" />
        </svg>
      </Link>
    </div>
  );
}
